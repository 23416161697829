import React, { useEffect, useMemo, useState } from 'react'
import Loading from 'components/Loading'
import { useFormik } from 'formik'
import {
  cepMask,
  cepUnmask,
  cpfCnpjUnmask,
  cpfCnpjMask,
  dateMask,
  telephoneMask,
  telephoneUnmask,
  cpfValidator,
} from 'js-essentials-functions'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Form,
  ModalBody,
  Modal,
  ModalHeader,
} from 'reactstrap'
import { updateClient } from 'services/clients'
import * as yup from 'yup'
import { alertSuccess, alertWarning } from 'utils/toast'
import { FormataStringData } from 'utils/formatData'
import { formatCPF } from 'utils/formatCPF'
import PermissionComponent from 'components/PermissionComponent'
import moment from 'moment'

interface Props {
  user: any
  uuid: string
  handleActiveUser: any
  handlePasswordTempory: any
}
const genders = [
  {
    label: 'Masculino',
    key: 'male',
  },
  {
    label: 'Feminino',
    key: 'female',
  },
  {
    label: 'Outros',
    key: 'other',
  },
]

export const schemaRegister = yup.object().shape({
  name: yup
    .string()
    .required('Informe o nome!')
    .test('no-spaces', 'O nome não deve conter espaços extras!', (value) =>
      value
        ? value.trim().split(/\s+/).length === value.split(' ').length
        : true
    )
    .test('two-words', 'Informe o nome completo!', (value) =>
      value ? value.trim().split(' ').length >= 2 : true
    ),

  telephone: yup
    .string()
    .required('Informe o telefone!')
    .when('country', {
      is: 'BR',
      then: yup
        .string()
        .test('cellphone-format', 'Digite um telefone válido', (value: any) => {
          const unmaskedValue = value ? telephoneUnmask(value) : ''
          return (
            !unmaskedValue ||
            (unmaskedValue.length === 11 &&
              /^\d{2}\d{5}\d{4}$/.test(unmaskedValue))
          )
        }),
    }),

  email: yup
    .string()
    .email('Digite um email válido')
    .required('Informe o email!'),

  birthDay: yup
    .string()
    .test('min-len', 'Data de nascimento inválida', (value) =>
      value ? value.length >= 10 : true
    )
    .test('birthdate', 'Escolha uma data de nascimento válida', (value) =>
      value ? moment(value, 'DD/MM/YYYY', true).isValid() : true
    )
    .test(
      'age',
      'Cadastro permitido apenas para maiores de 14 anos',
      (value) => {
        if (!value) return true
        const birthdate = moment(value, 'DD/MM/YYYY')
        const today = moment()
        const age = today.diff(birthdate, 'years')
        return age >= 14
      }
    )
    .test(
      'ageLimit',
      'Data de nascimento não pode ser superior a 120 anos',
      (value) => {
        if (!value) return true
        const birthdate = moment(value, 'DD/MM/YYYY')
        const today = moment()
        const age = today.diff(birthdate, 'years')
        return age <= 120
      }
    ),
  document: yup.string().when('country', {
    is: 'BR',
    then: yup
      .string()
      .test('len', 'CPF inválido!', (value: any) => {
        if (!value) return true
        const unmaskedValue = cpfCnpjUnmask(value)
        return unmaskedValue.length === 11
      })
      .test('valid-cpf', 'CPF inválido!', (value) => {
        if (!value) return true
        const formattedValue = formatCPF(value)
        return cpfValidator(formattedValue)
      }),
  }),

  zip: yup.string().test('valid-cep', 'CEP inválido!', (value) => {
    if (!value) return true
    const unmaskedValue = value ? value.replace(/\D/g, '') : ''
    return unmaskedValue.length === 8
  }),
  ddi: yup
    .string()
    .matches(
      /^\+\d{1,4}$/,
      'DDI inválido, deve começar com "+" seguido de 1 a 4 dígitos'
    )
    .required('O DDI é obrigatório'),

  country: yup.string().required('O país é obrigatório'),
})

const DataStar = ({
  uuid,
  handleActiveUser,
  handlePasswordTempory,
  user,
}: Props) => {
  const [editable, setEditable] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const initialValues = useMemo(
    () => ({
      name: '',
      document: '',
      birthDay: '',
      email: '',
      telephone: '',
      gender: '',
      street: '',
      number: '',
      complement: '',
      neighborhood: '',
      city: '',
      state: '',
      zip: '',
      ddi: '',
      country: '',
    }),
    []
  )

  const countries = {
    BR: 'Brasil',
    CA: 'Canadá',
    US: 'Estados Unidos',
    PT: 'Portugal',
  }

  const toggle = () => setModal(!modal)

  const formik = useFormik({
    initialValues,
    validationSchema: schemaRegister,
    onSubmit: async (values) => {
      if (!formik.isValid) {
        alertWarning('Há erros no formulário. Verifique os campos.')
        return
      }

      setLoading(true)

      const userData = {
        name: values.name,
        email: values.email,
        document: cpfCnpjUnmask(values.document),
        cellphone: telephoneUnmask(values.telephone),
        birthDay: FormataStringData(values.birthDay),
        ddi: values.ddi,
        gender: values.gender === '' ? null : values.gender,
      }
      const datasAdress = {
        city: values.city,
        complement: values.complement,
        neighborhood: values.neighborhood,
        number: values.number,
        state: values.state,
        street: values.street,
        zip: cepUnmask(values.zip),
        country: values.country,
      }
      const response = await updateClient({
        uuid,
        userData,
        address: datasAdress,
      })
      if (response.status) {
        alertSuccess(response.message)
        setLoading(false)
        setEditable(false)
      } else {
        alertWarning(response.message)
      }
      setLoading(false)
    },
  })

  const setDataUser = () => {
    formik.setValues({
      document: (user?.document && cpfCnpjMask(user?.document)) || '',
      name: user?.name,
      birthDay: user?.birthdate
        ? moment(user?.birthdate).format('DD/MM/YYYY')
        : '',
      telephone: user?.cellphone || '',
      email: user?.email || '',
      gender: user?.gender || '',
      ddi: user?.ddi || '',
      zip: user?.zipcode || '',
      street: user?.street || '',
      number: user?.number || '',
      city: user?.city || '',
      state: user?.state || '',
      neighborhood: user?.neighborhood || '',
      complement: user?.complement || '',
      country: user?.country || '',
    })
  }

  useEffect(() => {
    if (user) {
      setDataUser()
    }
  }, [user])

  const modalPassword = () => (
    <Modal isOpen={modal} toggle={toggle} style={{ maxWidth: 400 }}>
      <ModalHeader toggle={toggle}>
        <h3>Deseja enviar uma senha provisória para essa estrela?</h3>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col className="d-flex justify-content-between">
            <Button
              type="button"
              color="secondary"
              onClick={toggle}
              title="Cancelar envio de senha"
            >
              Cancelar
            </Button>
            <Button
              type="button"
              color="primary"
              onClick={() => {
                handlePasswordTempory(user?.id)
                setModal(false)
              }}
              title="Confirmar envio de senha"
            >
              Confirmar
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )

  return (
    <>
      {loading && <Loading title="Carregando..." />}
      {modalPassword()}
      <Form onSubmit={formik.handleSubmit}>
        <Card className="shadow">
          <CardHeader className="bg-transparent d-flex justify-content-between align-items-center">
            <Col sm="auto" className="p-0">
              <h2 className="mb-0 text-black">Dados da Estrela</h2>
            </Col>
            <PermissionComponent role="SUPERADMIN,ADMIN,SUPPORT">
              <Col sm="auto" className="d-flex p-0">
                {user?.status === 'active' ? (
                  <>
                    {editable && (
                      <Col sm="auto" className="d-flex p-0 ml-2">
                        <Button
                          type="button"
                          onClick={() => {
                            setEditable(false)
                            setDataUser()
                          }}
                          color="white"
                          title="Cancelar edição"
                        >
                          Cancelar
                        </Button>
                      </Col>
                    )}
                    {editable && (
                      <Col sm="auto" className="d-flex p-0 ml-2">
                        <Button
                          type="submit"
                          color="secondary"
                          title="Salvar alterações"
                        >
                          Salvar
                        </Button>
                      </Col>
                    )}
                    {!editable && (
                      <Col sm="auto" className="d-flex p-0 ml-2">
                        <Button
                          type="button"
                          color="secondary"
                          onClick={() => setEditable(true)}
                          title="Editar dados do usuário"
                        >
                          Editar
                        </Button>
                      </Col>
                    )}
                  </>
                ) : (
                  <Col sm="auto" className="d-flex p-0 ml-2">
                    <Button
                      type="button"
                      color="secondary"
                      onClick={() => handleActiveUser(user.id)}
                      title="Ativar usuário"
                    >
                      Ativar
                    </Button>
                  </Col>
                )}
                <Col sm="auto" className="d-flex p-0 ml-2">
                  <Button
                    color="primary"
                    onClick={() => setModal(true)}
                    title="Enviar senha provisória"
                  >
                    Enviar Senha Provisória
                  </Button>
                </Col>
              </Col>
            </PermissionComponent>
          </CardHeader>
        </Card>
        <Card className="shadow mt-1">
          <CardBody>
            <Row className="d-flex">
              <Col md="5">
                <FormGroup>
                  <Label for="name" style={{ fontSize: 14 }}>
                    Nome
                  </Label>
                  <Input
                    className={`form-control-alternative ${
                      formik.touched.name && formik.errors.name
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="input-username"
                    name="name"
                    type="text"
                    disabled={!editable}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    error={formik.errors.name}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className="invalid-feedback">{formik.errors.name}</div>
                  )}
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label for="document" style={{ fontSize: 14 }}>
                    Documento
                  </Label>
                  <Input
                    className={`form-control-alternative ${
                      formik.touched.document && formik.errors.document
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="input-username"
                    name="document"
                    type="text"
                    maxLength={15}
                    disabled={!editable}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.document}
                    error={formik.errors.document}
                  />
                  {formik.touched.document && formik.errors.document && (
                    <div className="invalid-feedback">
                      {formik.errors.document}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label for="birthDay" style={{ fontSize: 14 }}>
                    Data de nascimento
                  </Label>
                  <Input
                    className={`form-control-alternative ${
                      formik.touched.birthDay && formik.errors.birthDay
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="input-username"
                    name="birthDay"
                    type="text"
                    maxLength={10}
                    disabled={!editable}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={dateMask(formik.values.birthDay)}
                    error={formik.errors.birthDay}
                  />
                  {formik.touched.birthDay && formik.errors.birthDay && (
                    <div className="invalid-feedback">
                      {formik.errors.birthDay}
                    </div>
                  )}
                </FormGroup>
              </Col>
            </Row>

            <Row className="d-flex">
              <Col md="4">
                <FormGroup>
                  <Label for="email" style={{ fontSize: 14 }}>
                    Email
                  </Label>
                  <Input
                    className={`form-control-alternative ${
                      formik.touched.email && formik.errors.email
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="input-email"
                    name="email"
                    type="text"
                    disabled={!editable}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className="invalid-feedback">
                      {formik.errors.email}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col md="2">
                <FormGroup>
                  <Label for="name" style={{ fontSize: 14 }}>
                    DDI
                  </Label>
                  <Input
                    className={`form-control-alternative ${
                      formik.touched.ddi && formik.errors.ddi
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="input-username"
                    name="ddi"
                    type="text"
                    maxLength={15}
                    disabled={!editable}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.ddi}
                    error={formik.errors.ddi}
                  />
                  {formik.touched.ddi && formik.errors.ddi && (
                    <div className="invalid-feedback">{formik.errors.ddi}</div>
                  )}
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label for="name" style={{ fontSize: 14 }}>
                    Telefone
                  </Label>
                  <Input
                    className={`form-control-alternative ${
                      formik.touched.telephone && formik.errors.telephone
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="input-username"
                    name="telephone"
                    type="text"
                    maxLength={15}
                    disabled={!editable}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={telephoneMask(formik.values.telephone)}
                    error={formik.errors.telephone}
                  />
                  {formik.touched.telephone && formik.errors.telephone && (
                    <div className="invalid-feedback">
                      {formik.errors.telephone}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <label htmlFor="gender">Gênero</label>
                  <Input
                    type="select"
                    id="gender"
                    name="gender"
                    disabled={!editable}
                    value={formik.values.gender}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option disabled value="" label="Gênero" />
                    {genders.map((e) => (
                      <option key={e.key} value={e.key} label={e.label} />
                    ))}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <hr style={{ marginTop: 0 }} />
            <Row className="d-flex">
              <Col md="6">
                <FormGroup>
                  <Label for="street" style={{ fontSize: 14 }}>
                    Rua
                  </Label>
                  <Input
                    className="form-control-alternative"
                    id="input-username"
                    name="street"
                    type="text"
                    disabled={!editable}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.street}
                    error={formik.errors.street}
                  />
                </FormGroup>
              </Col>
              <Col md="2">
                <FormGroup>
                  <Label for="number" style={{ fontSize: 14 }}>
                    Nº
                  </Label>
                  <Input
                    className="form-control-alternative"
                    id="input-username"
                    name="number"
                    type="text"
                    disabled={!editable}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.number}
                    error={formik.errors.number}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label for="complement" style={{ fontSize: 14 }}>
                    Complemento
                  </Label>
                  <Input
                    className="form-control-alternative"
                    id="input-username"
                    name="complement"
                    type="text"
                    disabled={!editable}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.complement}
                    error={formik.errors.complement}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label for="neighborhood" style={{ fontSize: 14 }}>
                    Bairro
                  </Label>
                  <Input
                    className="form-control-alternative"
                    id="input-username"
                    name="neighborhood"
                    type="text"
                    disabled={!editable}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.neighborhood}
                    error={formik.errors.neighborhood}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label for="city" style={{ fontSize: 14 }}>
                    Cidade
                  </Label>
                  <Input
                    className="form-control-alternative"
                    id="input-username"
                    name="city"
                    type="text"
                    disabled={!editable}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.city}
                    error={formik.errors.city}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label for="state" style={{ fontSize: 14 }}>
                    Estado
                  </Label>
                  <Input
                    className="form-control-alternative"
                    id="input-username"
                    name="state"
                    type="text"
                    disabled={!editable}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.state}
                    error={formik.errors.state}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="d-flex">
              <Col md="4">
                <FormGroup>
                  <Label for="zip" style={{ fontSize: 14 }}>
                    Pais
                  </Label>
                  <Input
                    className={`form-control-alternative ${
                      formik.touched.country && formik.errors.country
                        ? 'is-invalid'
                        : ''
                    }`}
                    type="select"
                    id="input-country"
                    name="country"
                    disabled={!editable}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.country}
                    error={formik.errors.country}
                  >
                    <option value="" label="Selecione o país" />
                    {Object.entries(countries).map(([code, name]) => (
                      <option key={code} value={code}>
                        {name}
                      </option>
                    ))}
                  </Input>
                  {formik.errors.country && formik.touched.country && (
                    <div className="input-feedback">
                      {formik.errors.country}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label for="zip" style={{ fontSize: 14 }}>
                    CEP
                  </Label>
                  <Input
                    className={`form-control-alternative ${
                      formik.touched.zip && formik.errors.zip
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="input-username"
                    name="zip"
                    type="text"
                    maxLength={9}
                    disabled={!editable}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={cepMask(formik.values.zip)}
                    error={formik.errors.zip}
                  />
                  {formik.errors.zip && formik.touched.zip && (
                    <div className="input-feedback">{formik.errors.zip}</div>
                  )}
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Form>
    </>
  )
}

export default DataStar
